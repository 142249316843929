<!--<template>
   <v-app>
     <template v-if="!isLoggedIn">
       <router-view></router-view>
     </template>
     <template v-else @Login::loginResult="handleLoginResult">
     <v-main>
       <HelloWorld/>
     </v-main>
     </template>
   </v-app>
</template> 
<template>
    <div class="jumbotron">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 offset-sm-3">
                    <div v-if="alert.message" :class="`alert ${alert.type}`">{{alert.message}}</div>
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>-->

<!--<template>
    <div>
        <nav v-if="this.$store.state.authentication.user" class="navbar navbar-expand navbar-dark bg-dark">
            <div class="navbar-nav">
                <router-link to="/" class="nav-item nav-link">Home</router-link>
                <router-link v-if="isAdmin" to="/admin" class="nav-item nav-link">Admin</router-link>
                <a @click="logout" class="nav-item nav-link">Logout</a>
            </div>
        </nav>
        <div class="jumbotron">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 offset-sm-3">
                        <router-view></router-view>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>-->
<template>
  <v-app id="gorenc">
    <!-- <vue-progress-bar v-if="this.$store.state.authentication.user"> </vue-progress-bar> -->
    <template v-if="!this.$store.state.authentication.user">
      <router-view></router-view>
    </template>
    <template v-if="this.$store.state.authentication.user">
      <v-navigation-drawer
        class="teal lighten-5"
        mini-variant-width="70"
        width="250"
        light
        :mini-variant.sync="mini"
        v-model="drawer"
        app
      >
        <v-list class="pa-0">
          <v-list-item tag="div">
            <v-list-item-action>
              <v-img
                max-width="3em"
                class="avatar"
                src="@/assets/logo_vikis.jpg"
              />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="gray--text"
                >{{ this.$store.state.authentication.user.firstName }}
                {{
                  this.$store.state.authentication.user.lastName
                }}</v-list-item-title
              >
            </v-list-item-content>
            <!-- <v-spacer></v-spacer> -->
            <v-list-item-action style="max-width:1em">
              <v-menu
                bottom
                right
                offset-y
                origin="bottom right"
                transition="v-slide-y-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-btn icon small light slot="activator" v-on="on">
                    <v-icon class="gray--text">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <!-- <v-list v-if="isAdmin"> -->
                <v-list>
                  <v-list-item
                    v-for="item in userMenus"
                    :key="item.title"
                    value="true"
                    @click="handleNavigtiion(item)"
                    router
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        class="gray--text"
                        v-text="item.title"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
            <v-list-item-action style="max-width:1em">
              <v-btn icon small @click.native.stop="mini = !mini">
                <v-icon class="gray--text">mdi-chevron-left</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-img src="./assets/logo_vikis.jpg" width="249px" dark></v-img>
        <v-list>
          <!-- <v-list v-if="isAdmin"> -->
          <v-list-item
            v-for="item in items"
            :key="item.title"
            @click="handleNavigtiion(item)"
            router
          >
            <v-list-item-action
              class="pr-1 pl-2 mr-1"
              v-if="item.role.includes(getRole)"
            >
              <v-icon
                :class="item.title ? 'gray--text' : ''"
                :title="item.title"
              >
                {{ item.icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content :class="item.title ? 'gray--text' : ''"  v-if="item.role.includes(getRole)">
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <!-- <v-app-bar app elevation="1" color="cyan lighten-5"> -->
      <v-app-bar app elevation="1" color="teal lighten-5">
        <v-app-bar-nav-icon
          @click.native.stop="drawer = !drawer"
          light
        ></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <!-- <div class="text-xs-center pr-3 mt-2">
          <v-badge left="">
            <span slot="badge">0</span>
            <v-icon large color="grey lighten-1">mdi-bell-outline</v-icon>
          </v-badge>

           <v-badge color="red">
            <span slot="badge">!</span>
            <v-icon large color="grey">mdi-email-outline</v-icon>
          </v-badge> 
        </div> -->

        <!-- <v-btn light text href="https://github.com/harryho/vue2crm" target="_blank">
          <svg height="30" class="octicon octicon-mark-github" viewBox="0 0 16 16" version="1.1" width="32" aria-hidden="true">
            <path
              fill-rule="evenodd"
              d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0 0 16 8c0-4.42-3.58-8-8-8z"
            ></path>
          </svg>
          <span style="margin-left:0.4rem;">GitHub</span>
        </v-btn> -->
      </v-app-bar>
      <v-main>
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </v-main>
      <v-footer
        :inset="true"
        style="justify-content:center; text-align: center"
        app
        color="teal lighten-5"
      >
        <span>&copy; Darilna kartica 2021 v1.0</span>
      </v-footer>
    </template>
  </v-app>
</template>
<script>
// import HelloWorld from './components/HelloWorld';
// import { Role } from './_helpers';
import { authenticationService } from "@/_services";
import router from "./router";
import { EventBus } from "./main";

export default {
  name: "App",
  created() {
    document.title = "Darilna kartica";
  },
  components: {
    // HelloWorld
  },
  data() {
    return {
      dialog: false,
      mini: false,
      dialogText: "",
      dialogTitle: "",
      isRootComponent: true,
      drawer: window.innerWidth > 960,
      fixed: false,
      scan: false,
      userIsLoggedIn: false,
      userMenus: [
        {
          icon: "bubble_chart",
          title: "Izpis",
          link: "login",
        },
        {
          icon: "bubble_chart",
          title: "Spremeni geslo",
          link: "changepassword",
        },
      ],
      items: [
        // {
        //   icon: 'mdi-view-dashboard',
        //   title: 'Poročila',
        //   vertical: 'Dashboard',
        //   link: 'dashboard'
        // },
        {
          icon: "mdi-barcode",
          title: "BAR vnos",
          vertical: "Scan",
          link: "scan",
          role: ["Admin", "User"],
        },
        {
          icon: "mdi-view-list",
          title: "Transakcije",
          vertical: "Transactions",
          link: "transactions",
          role: ["Admin", "User"],
        },
        {
          icon: "mdi-credit-card",
          title: "Kartice",
          vertical: "Cards",
          link: "cards",
          role: ["Admin"],
        },
        {
          icon: "mdi-account-multiple",
          title: "Uporabniki",
          vertical: "Users",
          link: "users_admin",
          role: ["Admin"],
        },
        // {
        //   icon: 'mdi-information-outline',
        //   title: 'Blagajna',
        //   vertical: 'Cards',
        //   link: 'cards'
        // },
        // {
        //   icon: 'mdi-information-outline',
        //   title: 'Camera',
        //   vertical: 'Camera',
        //   link: 'scanner'
        // },
        // {
        //   icon: 'mdi-information-outline',
        //   title: 'O aplikaciji',
        //   vertical: 'About',
        //   link: 'about'
        // }
      ],
    };
  },
  computed: {
    isLoggedIn() {
      return this.userIsLoggedIn;
    },
    alert() {
      return this.$store.state.alert;
    },
    isAdmin() {
      console.log(this.$store.state.authentication.user.role);
      return (
        this.$store.state.authentication.user &&
        this.$store.state.authentication.user.role === "Admin"
      );
    },
    getRole() {
      console.log(this.$store.state.authentication.user.role);
      return this.$store.state.authentication.user.role;
    },
    isLog() {
      return localStorage.getItem("user");
    },
  },
  methods: {
    handleLoginResult({ loginResult }) {
      this.userIsLoggedIn = loginResult;
    },
    logout() {
      authenticationService.logout();
      router.push("/login");
    },
    handleNavigtiion(item) {
      this.menuItem = item.title;
      if (this.$route.path != "/" + item.link) {
        this.$router.push({
          name: item.link,
        });
      } else if (this.$route.path == "/" + item.link && item.link == "scan") {
        EventBus.$emit("scan", true);
      }
    },
  },
  watch: {
    // tuki ubistvu gledamo ruto v urlju in ko se spremeni se cleara alert
    // $route (to, from){
    //     // clear alert on location change
    //     this.$store.dispatch('alert/clear');
    // }
  },
};
</script>
