<template>
  <v-container fill-width>
    <v-row>
      <v-card class="mx-auto" max-width="800" outlined v-show="cardScanned">
        <v-list-item three-line>
          <v-list-item-content>
            <p class="display-1 text--primary">
              <v-icon left>
                {{ icons.mdiCreditCard }}
              </v-icon>
              Darilna kartica
            </p>
            <barcode v-bind:value="barcodeValue" font-options="bold">
              <!-- Show this if the rendering fails. -->
            </barcode>
            <v-list-item-title class="headline mb-1">
              Stanje: <b>{{ tfBalance | formatQuantity }}€</b>
            </v-list-item-title>
            <v-card-text class="headline mb-1">
              Datum izdaje {{ cardIssuedAt }}
            </v-card-text>
          </v-list-item-content>
        </v-list-item>
        <v-card-actions class="justify-center">
          <v-btn color="red" dark @click="btnBuy = true">
            <v-icon left>
              {{ icons.mdiMinusThick }}
            </v-icon>
            Odštej vrednost
          </v-btn>

          <v-btn color="green" dark @click="btnTop = true">
            <v-icon left>
              {{ icons.mdiPlusThick }}
            </v-icon>
            Napolni
          </v-btn>
        </v-card-actions>
        <v-card-actions class="justify-center">
          <v-btn color="teal white--text" dark v-on:click="createPdf">
            <v-icon left>
              {{ icons.mdiPrinter }}
            </v-icon>
            Natisni
          </v-btn>
          <v-btn color="orange" dark @click="lastTrans">
            <v-icon left>
              {{ icons.mdiDelete }}
            </v-icon>
            Storniraj
          </v-btn>
          <v-btn color="blue" dark @click="btnOwner = true">
            <v-icon left>
              {{ icons.mdiAccountPlus }}
            </v-icon>
            Lastnik
          </v-btn>
        </v-card-actions>
        <!-- </v-card-text> -->
      </v-card>
    </v-row>
    <v-btn
      color="teal white--text"
      dark
      @click="dialog = true"
      fixed
      fab
      x-large
      bottom
      right
      v-blur
    >
      <v-icon>
        {{ icons.mdiBarcodeScan }}
      </v-icon>
    </v-btn>
    <!-- <v-row justify="center"> -->
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Čakam vnos kode...</span>
          <v-progress-linear
            color="green accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Vnesi BAR kodo*"
                  v-model="bCodeInput"
                  required
                  solo
                  ref="searchFocus"
                  @input="charCount"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*prosim preberite črtno kodo</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="teal white--text" @click="dialog = false">
            Zapri
          </v-btn>
          <v-btn color="teal white--text" v-on:click="sendCode">
            Potrdi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="btnTop" width="600px">
      <v-card class="mx-auto" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="overline mb-4">
              Napolni kartico
            </div>
            <v-list-item-title class="headline mb-1">
              Dodaj dobroimetje
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-card-text>
          <v-currency-field
            label="Višina dobroimetja v €"
            v-model="txtTopUp"
            solo
          />
          <v-btn color="red" dark v-on:click="btnTop = false">
            <v-icon left>
              {{ icons.mdiCloseBox }}
            </v-icon>
            Zapri </v-btn
          >&nbsp;
          <v-btn color="green" dark v-on:click="deductValue(1)">
            <v-icon left>
              {{ icons.mdiPlusThick }}
            </v-icon>
            Potrdi
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="btnOwner" width="600px">
      <v-card class="mx-auto" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="overline mb-4">
              Dodaj lastnika
            </div>
            <v-list-item-title class="headline mb-1">
              Podatki lastnika
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-card-text>
          <v-text-field
            label="Ime in priimek lastnika€"
            v-model="txtOwner"
            solo
          />
          <v-btn color="red" dark v-on:click="btnOwner = false">
            <v-icon left>
              {{ icons.mdiCloseBox }}
            </v-icon>
            Zapri </v-btn
          >&nbsp;
          <v-btn color="green" dark v-on:click="addOwner()">
            <v-icon left>
              {{ icons.mdiPlusThick }}
            </v-icon>
            Potrdi
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="btnBuy" width="600px">
      <v-card class="mx-auto" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="overline mb-4">
              Uveljavi popust
            </div>
            <v-list-item-title class="headline mb-1">
              Odštej vrednost
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-card-text>
          <!-- <v-currency-field
            label="Višina dobroimetja v €"
            v-model="txtDeduct"
            solo
          /> -->
          <v-currency-field
            label="Odštej znesek"
            suffix="€"
            solo
            ref="deduct"
            v-model="bCodeNewBalance"
          />
          <v-btn color="red" dark v-on:click="btnBuy = false">
            <v-icon left>
              {{ icons.mdiCloseBox }}
            </v-icon>
            Zapri </v-btn
          >&nbsp;
          <v-btn color="green" dark v-on:click="deductValue(0)">
            <v-icon left>
              {{ icons.mdiPlusThick }}
            </v-icon>
            Potrdi
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="btnStorno" width="600px">
      <v-card class="mx-auto" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="overline mb-4">
              Storniraj transakcijo
            </div>
            <v-list-item-title class="headline mb-1">
              Zadnja transakcija kartice
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <div>
          Čas: {{ lastTransDate }}. Znesek: {{ lastTranVal }}€ &nbsp;
          <v-chip
            :color="
              lastTransStatus == '1'
                ? 'green'
                : lastTransStatus == '0'
                ? 'red'
                : 'grey'
            "
            dark
          >
            {{
              lastTransStatus == "1"
                ? "Aktivna"
                : lastTransStatus == "0"
                ? "Neaktivna"
                : "grey"
            }}
          </v-chip>
        </div>
        <v-card-text>
          <v-btn color="orange" dark v-on:click="storno">
            <v-icon left>
              {{ icons.mdiDelete }}
            </v-icon>
            Storniraj
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="warning" :timeout="timeout">
      {{ emWarning }}
      <template v-slot:action="{ attrs }">
        <v-btn color="teal white--text" v-bind="attrs" @click="warning = false">
          Zapri
        </v-btn>
      </template>
    </v-snackbar>

    <!-- </v-row> -->
  </v-container>
</template>
<script>
import axios from "axios";
import * as Printjs from "print-js";
import { EventBus } from "../main";
import VueBarcode from "vue-barcode";
import router from "../router";
import {
  mdiDelete,
  mdiPlusThick,
  mdiMinusThick,
  mdiCreditCard,
  mdiBarcodeScan,
  mdiCheck,
  mdiPrinter,
  mdiCloseBox,
  mdiAccountPlus,
} from "@mdi/js";

export default {
  components: {
    barcode: VueBarcode,
  },
  data: () => ({
    icons: {
      mdiDelete,
      mdiPlusThick,
      mdiMinusThick,
      mdiCreditCard,
      mdiBarcodeScan,
      mdiCheck,
      mdiPrinter,
      mdiCloseBox,
      mdiAccountPlus,
    },
    dialog: true,
    bCode: "",
    bCodeInput: "",
    bCodeNewBalance: "",
    barcodeValue: "123465",
    btnTop: false,
    btnStorno: false,
    txtTopUp: "",
    txtOwner: "",
    btnBuy: false,
    btnOwner: false,
    tfBalance: 50,
    lvCodeId: "",
    dateRegistered: "",
    warning: false,
    emWarning: "",
    timeout: 2000,
    lastDate: "",
    lastTranVal: "",
    lastTransId: "",
    lastTransDate: "",
    lastTransBalance: "",
    lastTransStatus: "",
    cardIssuedAt: "",
    cardScanned: false,
    deductF: false,
    scan: false,
  }),
  mounted() {
    const self = this;
    EventBus.$on("scan", function() {
      self.dialog = true;
    });
  },
  watch: {
    dialog(visible) {
      if (visible) {
        console.log(visible);
        // console.log(this.$refs.searchFocus.focus);
        this.bCodeInput = null;
        setTimeout(() => {
          this.$refs.searchFocus.focus();
        });
      }
    },
  },
  created() {
    this.clear_data();
    setTimeout(() => {
      this.$refs.searchFocus.focus();
    });
  },
  methods: {
    charCount() {
      // console.log(e);

      if (this.bCodeInput.length == 12) {
        this.dialog = false;
        this.sendCode();
      }
    },
    clear_data() {
      this.tfBalance = "";
      this.barcodeValue = "";
      this.lvCodeId = "";
      this.dateRegistered = "";
      this.lastDate = "";
      this.lastTranVal = "";
      this.lastTransId = "";
      this.cardIssuedAt = "";
      this.bCode = "";
      this.cardScanned = true;
      this.txtOwner = "";
    },
    sendCode() {
      this.dialog = false;
      this.bCode = this.bCodeInput;

      // this.bCode = "";
      let user = JSON.parse(localStorage.getItem("user"));
      axios
        .get("/api/card", {
          headers: { Authorization: "Bearer " + user.token },
          params: { id: this.bCode },
        })
        .then(
          (response) => {
            // console.log(response);
            this.tfBalance = response.data.balance;
            this.barcodeValue = response.data.code;
            this.lvCodeId = response.data.id;
            this.dateRegistered = response.data.dateRegistered;
            this.lastDate = response.data.transDate;
            this.lastTranVal = response.data.transValue;
            this.lastTransId = response.data.lastTransId;
            this.cardIssuedAt = response.data.issuedAt;
            this.cardScanned = true;
            this.txtOwner = response.data.title;
            // setTimeout(() => {
            //   this.$refs.deduct.$refs.textfield.focus();
            // });
            if (!response.data.issuedAt) {
              // console.log("ni reg");
              this.cardIssuedAt = "Kartica še ni registrirana!";
              this.$confirm("Kartica še ni registrirana, želiš registrirati?", {
                title: "Registracija kartice",
                buttonTrueText: "Registriraj",
                buttonFalseText: "Zavrzi",
                color: "green",
              }).then((res) => {
                if (res) {
                  axios
                    .post(
                      "/api/card_status_reg",
                      {
                        card_id: this.lvCodeId,
                        stat: true,
                      },
                      { headers: { Authorization: "Bearer " + user.token } }
                    )
                    .then(
                      (response) => {
                        // console.log(response);
                        this.cardIssuedAt = response.data.issuedAt;
                      },
                      (error) => {
                        console.log(error.response.data);
                        // console.log(error);
                      }
                    );
                }
              });
            } else if (!response.data.active) {
              this.$confirm("Kartica ni aktivna, želiš aktivirati?", {
                title: "Aktivacija kartice",
                buttonTrueText: "Aktiviraj",
                buttonFalseText: "Zavrzi",
                color: "green",
              }).then((res) => {
                if (res) {
                  axios.post(
                    "/api/card_status",
                    {
                      card_id: response.data.id,
                      stat: true,
                    },
                    { headers: { Authorization: "Bearer " + user.token } }
                  );
                }
              });
            }
          },
          (error) => {
            if (error.response.status === 401) {
              localStorage.removeItem("user");
              router.push("/login");
            }
          }
        );
    },
    lastTrans() {
      let user = JSON.parse(localStorage.getItem("user"));
      axios
        .get("/api/last_transaction", {
          headers: { Authorization: "Bearer " + user.token },
          params: { bCode: this.bCode },
        })
        .then(
          (response) => {
            // console.log(response);
            // {"newBalance":"21","diffBalance":"-2","createdAt":"20/03/2021 0:03:44","updatedAt":"20/03/2021 0:03:44","id":5,"active":true,"user":"admin","CardId":1,"Card":{"code":"123"}}
            this.lastTransId = response.data.id;
            this.lastTranVal = response.data.diffBalance;
            this.lastTransDate = response.data.updatedAt;
            this.lastTransBalance = response.data.newBalance;
            this.lastTransStatus = response.data.active;
          },
          (error) => {
            console.log(error);
          }
        );
      this.btnStorno = true;
    },
    topup() {},
    deduct() {},
    storno() {
      let user = JSON.parse(localStorage.getItem("user"));
      axios
        .post(
          "/api/last_transaction",
          {
            transID: this.lastTransId,
            active: false,
          },
          { headers: { Authorization: "Bearer " + user.token } }
        )
        .then(
          (response) => {
            // console.log(response);
            // this.sendCode();
            this.tfBalance = response.data.balance;
            this.barcodeValue = response.data.code;
            this.lvCodeId = response.data.id;
            this.dateRegistered = response.data.dateRegistered;
            this.btnStorno = false;
          },
          (error) => {
            console.log(error.response.data);
          }
        );
    },
    addOwner() {
      let user = JSON.parse(localStorage.getItem("user"));
      axios
        .post(
          "/api/owner",
          {
            cardId: this.lvCodeId,
            code: this.barcodeValue,
            owner: this.txtOwner,
          },
          { headers: { Authorization: "Bearer " + user.token } }
        )
        .then(
          (response) => {
            console.log(response);
            this.btnOwner = false;
            // this.txtOwner = "";
            return;
          },
          (error) => {
            console.log(error.response.data);
            // this.txtOwner = "";
            if (
              Object.prototype.hasOwnProperty.call(
                error.response.data,
                "errTxt"
              )
            ) {
              this.warning = true;
              this.emWarning = error.response.data.errTxt;
            }
          }
        );
    },
    deductValue(methodType) {
      // console.log(this.lvCodeId);
      let user = JSON.parse(localStorage.getItem("user"));
      let balance = 0;
      if (methodType == 0) {
        balance = -Math.abs(this.bCodeNewBalance);
      } else {
        balance = Math.abs(this.txtTopUp);
      }
      if (balance == 0) {
        this.warning = true;
        this.emWarning = "Vnesite znesek različen od 0";
        if (methodType == 0) {
          this.btnBuy = false;
        } else {
          this.btnTop = false;
        }

        this.bCodeNewBalance = "";
        this.txtTopUp = "";
        return;
      }

      if (this.tfBalance + balance < 0) {
        this.warning = true;
        this.emWarning = "Ni dovolj razporožljivih količin";
        if (methodType == 0) {
          this.btnBuy = false;
        } else {
          this.btnTop = false;
        }

        this.bCodeNewBalance = "";
        this.txtTopUp = "";
        return;
      }
      axios
        .post(
          "/api/card",
          {
            cardId: this.lvCodeId,
            code: this.barcodeValue,
            changeBalance: balance,
          },
          { headers: { Authorization: "Bearer " + user.token } }
        )
        .then(
          (response) => {
            console.log(response);
            if (methodType == 0) {
              this.btnBuy = false;
            } else {
              this.btnTop = false;
            }
            this.bCodeNewBalance = "";
            this.txtTopUp = "";
            this.tfBalance = response.data.balance;
            this.cardIssuedAt = response.data.issuedAt;
          },
          (error) => {
            console.log(error.response.data);

            if (
              Object.prototype.hasOwnProperty.call(
                error.response.data,
                "errTxt"
              )
            ) {
              this.warning = true;
              this.emWarning = error.response.data.errTxt;
            }
            if (methodType == 0) {
              this.btnBuy = false;
            } else {
              this.btnTop = false;
            }
            this.bCodeNewBalance = "";
            this.txtTopUp = "";
          }
        );
    },
    createPdf() {
      let user = JSON.parse(localStorage.getItem("user"));
      axios
        .post(
          "/api/create_pdf",
          {
            // transID: this.lastTransId,
            card_id: this.lvCodeId,
          },
          { headers: { Authorization: "Bearer " + user.token } }
        )
        .then(
          (response) => {
            // console.log(response.data);
            // var a = document.createElement("a"); //Create <a>
            // a.href = "data:application/pdf;base64," + response.data; //Image Base64 Goes here
            // a.download = "abc.pdf"; //File name Here
            // a.click(); //Downloaded file
            Printjs({ printable: response.data, type: "pdf", base64: true });
          },
          (error) => {
            console.log(error.response.data);
            // console.log(error);
          }
        );
    },
  },
  computed: {
    tfBalanceComp: function() {
      return this.tfBalance.toString().replace(".", ",");
    },
  },
  filters: {
    // you can call this whatever you want to

    formatQuantity(value) {
      // value = parseFloat(value).toFixed(2);
      value = value.toString();
      return value.replace(".", ",");
    },
  },
};
</script>
