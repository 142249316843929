import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
// import Login from '../views/Login.vue'
// import HomePage from '../views/HomePage'
import LoginPage from '../views/LoginPage'
import AdminPage from '../views/AdminPage';
import ScannerPage from '../views/ScannerPage'
import ScanPage from '../views/ScanPage'
import CardsPage from '../views/CardsPage'
import TransactionPage from '../views/TransactionPage'
import ChangePasswordPage from '../views/ChangePasswordPage'
import UsersPage from '../views/UsersPage'
// import { Role } from './_helpers';


Vue.use(VueRouter)

// const routes = [
//   {
//     path: '/',
//     name: 'Login',
//     component: Login
//   },
//   {
//     path: '/home',
//     name: 'Home',
//     component: Home
//   },
//   {
//     path: '/about',
//     name: 'About',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//   }
// ]
const Role = {
  Admin: 'Admin',
  User: 'User'
};
const routes = [
    // { path: '/', component: HomePage, meta: { authorize: [] }  },
    { path: '/login', component: LoginPage, name: "login" },
    { path: '/changepassword', component: ChangePasswordPage, name: "changepassword", meta: { authorize: [Role.Admin, Role.User] }  },
    
    { 
      path: '/admin', 
      component: AdminPage, 
      meta: { authorize: [Role.Admin] } 
    },
    { 
      path: '/scanner', 
      component: ScannerPage, 
      name: "scanner",
      meta: { authorize: [Role.Admin, Role.User] } 
    },
    { 
      path: '/scan', 
      component: ScanPage, 
      name: "scan",
      meta: { authorize: [Role.Admin, Role.User] } 
    },
    { 
      path: '/cards', 
      component: CardsPage, 
      name: "cards",
      meta: { authorize: [Role.Admin, Role.User] } 
    },
    { 
      path: '/transactions', 
      component: TransactionPage, 
      name: "transactions",
      meta: { authorize: [Role.Admin, Role.User] } 
    },
    { 
      path: '/users_admin', 
      component: UsersPage, 
      name: "users_admin",
      meta: { authorize: [Role.Admin, Role.User]  } 
    },
    // otherwise redirect to home
    { path: '*', redirect: '/scan' }
  ]
;
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  // const publicPages = ['/login'];
  // const authRequired = !publicPages.includes(to.path);
  
  const loggedIn = localStorage.getItem('user');
  console.log(authorize);
  console.log(loggedIn);
  
  const { authorize } = to.meta;
  if(authorize){
    if (!loggedIn) {
      return next({ path: '/login', query: { returnUrl: to.path } });
    }
     // check if route is restricted by role
     if (authorize.length && !authorize.includes(JSON.parse(loggedIn).role)) {
      // role not authorised so redirect to home page
      console.log(authorize);
      console.log(loggedIn);
      return next({ path: '/' });
    }
  }
  

  next();
})
export default router
